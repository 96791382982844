<p-sidebar [(visible)]="isShow" position="top" [style]="{'height': 'auto'}">
  <div class="menu-area flex justify-content-center">
    <div class="menu-item" *ngIf="display('case-list')" (click)="onClick('case-list')">
      <div class="icon"><i class="pi pi-list"></i></div>
      <div class="title">Case List</div>
    </div>
    <div class="menu-item" *ngIf="display('new-case')" (click)="onClick('new-case')">
      <div class="icon"><i class="pi pi-plus"></i></div>
      <div class="title">New Case</div>
    </div>
    <div class="menu-item" *ngIf="display('accounts')" (click)="onClick('accounts')">
      <div class="icon"><i class="pi pi-users"></i></div>
      <div class="title">Accounts</div>
    </div>
    <div class="menu-item" *ngIf="display('tongues')" (click)="onClick('tongues')">
      <div class="icon"><i class="pi pi-tags"></i></div>
      <div class="title">Tongues</div>
    </div>
    <div class="menu-item" *ngIf="display('personal-account')" (click)="onClick('personal-account')">
      <div class="icon"><i class="pi pi-user"></i></div>
      <div class="title">{{account}}</div>
    </div>
  </div>
</p-sidebar>
<div class="menu-button">
  <button pButton icon="pi pi-bars" (click)="toggle()"></button>
</div>
