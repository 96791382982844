<p-dialog header="{{header}}" [(visible)]="isShow">
  <div class="dialog-area" *ngIf="editedTongue">
    <div class="mt-3">
      <div *ngIf="originalImage">
        <img [src]="originImageUrl" (click)="imageUploader.show()">
      </div>
      <div *ngIf="!originalImage">
        <ng-container *ngIf="imageUploader && imageUploader.image">
          <img [src]="imageUploader.displayDataUrl" (click)="imageUploader.show()">
        </ng-container>
        <ng-container *ngIf="imageUploader && !imageUploader.image">
          <button pButton label="Select Icon" (click)="imageUploader.show()" class="w-5rem"></button>
        </ng-container>
      </div>
    </div>
    <div class="mt-3 flex align-content-center">
      <div class="row-header">Name: </div>
      <div class="row-body">
        <input pInputText type="text" [(ngModel)]="editedTongue.name">
      </div>
    </div>
    <div class="mt-3 flex">
      <div class="row-header">TCM Pattern: </div>
      <div class="row-body">
        <input pInputText type="text" [(ngModel)]="editedTongue.pattern">
      </div>
    </div>
    <div class="mt-3 flex">
      <div class="row-header">Symptoms of Male: </div>
      <div class="row-body">
        <input pInputText type="text" [(ngModel)]="editedTongue.symptomsMale">
      </div>
    </div>
    <div class="mt-3 flex">
      <div class="row-header">Symptoms of Female: </div>
      <div class="row-body">
        <input pInputText type="text" [(ngModel)]="editedTongue.symptomsFemale">
      </div>
    </div>
    <div class="mt-3 flex">
      <div class="row-header">Recommendation: </div>
      <div class="row-body">
        <input pInputText type="text" [(ngModel)]="editedTongue.recommendation">
      </div>
    </div>
    <div class="mt-3">
      <p-selectButton [options]="statusOptions" [(ngModel)]="editedTongue.status"></p-selectButton>
    </div>
    <div class="mt-3">
      <button pButton label="Save" (click)="onSave()"></button>
    </div>
    <tudi-image-file-selector (onUpload)="onImageUpload($event)"></tudi-image-file-selector>
  </div>
</p-dialog>

