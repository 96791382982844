import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { JsonCaseReport } from 'src/model/dto';
import { AppService } from '../app.service';

@Component({
  selector: 'app-show-report',
  templateUrl: './show-report.component.html',
  styleUrls: ['./show-report.component.scss']
})
export class ShowReportComponent implements OnInit {
  report: JsonCaseReport;
  caseImg: string;
  error: string;

  constructor(
    private route: ActivatedRoute,
    private service: AppService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((res: Params) => {
      console.log(res);
      if (res.caseId && res.token) {
        this.getReportData(res.caseId, res.token);
      } else {
        this.error = 'Please confirm the URL.'
      }
    });
  }

  getReportData(caseId: number, token: string): void {
    const data = {
      caseId: caseId,
      token: token
    };
    this.service.post('data', 'viewCaseReport', data).subscribe(res => {
      this.report = res;
      this.caseImg = this.service.generateGetUrl('data', 'getCaseTongueImage', data);
    }, err => {
      this.error = 'Please confirm the URL.'
    });
  }
}
