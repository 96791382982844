<p-dialog header="{{header}}" [(visible)]="isShow">
  <div class="dialog-area" *ngIf="currentAccount">
    <div class="mt-3 flex">
      <div class="field-header">
        <label for="accountInputText">Account:</label>
      </div>
      <div class="field-body">
        <input id="accountInputText" [disabled]="currentAccount.id !== 0" type="text" pInputText [(ngModel)]="currentAccount.account">
      </div>
    </div>
    <div class="mt-3 flex">
      <div class="field-header">
        <label for="nameInputText">Name:</label>
      </div>
      <div class="field-body">
        <input id="nameInputText" type="text" pInputText [(ngModel)]="currentAccount.name">
      </div>
    </div>
    <div class="mt-3 flex">
      <div class="field-header">
        <label for="passwordInputText">Password:</label>
      </div>
      <div class="field-body">
        <input id="passwordInputText" type="password" pInputText [(ngModel)]="currentAccount.password" placeholder="{{passwordPlaceHolder}}">
      </div>
    </div>
    <div class="mt-3 flex">
      <div class="field-header">
        <label for="roleSelectButton">Role:</label>
      </div>
      <div class="field-body">
        <p-selectButton inputId="roleSelectButton" [options]="roleOptions" [(ngModel)]="currentAccount.role"></p-selectButton>
      </div>
    </div>
    <div class="mt-3 flex">
      <div class="field-header">
        <label for="statusSelectButton">Status:</label>
      </div>
      <div class="field-body">
        <p-selectButton inputId="statusSelectButton" [options]="statusOptions" [(ngModel)]="currentAccount.status"></p-selectButton>
      </div>
    </div>
    <div class="mt-3">
      <button pButton label="Save" (click)="onSave()"></button>
    </div>
  </div>
</p-dialog>