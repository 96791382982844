import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AppService } from './app.service';
import { JsonLogin, MemberRole } from 'src/model/dto';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(
    private appService: AppService,
    private router: Router
  ) { }

  canActivate(routeSnapshot: ActivatedRouteSnapshot): boolean {
    let loginInfo: JsonLogin = this.appService.loginInfo;
    if (!loginInfo && localStorage.getItem('loginInfo')) {
      loginInfo = JSON.parse(localStorage.getItem('loginInfo'));
      this.appService.loginInfo = loginInfo;
    }
    if (loginInfo) {
      if (routeSnapshot.url.toString().indexOf('admin') !== -1 && loginInfo.role !== MemberRole.admin) {
        this.router.navigate(['login']);
        return false;
      }
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }
}