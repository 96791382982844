import { Component } from '@angular/core';
import { AppService } from '../app.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  account: string = '';
  password: string = '';

  constructor(
    private appService: AppService
  ) { }

  ngOnInit(): void {
    this.testing();
  }

  testing(): void {
    // this.appService.login('linhuanyu', '123456');
  }

  onLogin(): void {
    this.appService.login(this.account, this.password);
  }
}
