export class StringUtility {
  // 依照數字長度，將不足的長度左邊補上0
  static padLeft(str: string, len: number): string {
    if (str.length > len) {
      return str;
    } else {
      return new Array(len - str.length + 1).join('0') + str;
    }
  }

  static distinct(list: string[]): string[] {
    const result: string[] = [];
    list.forEach(item => {
      if (result.indexOf(item) < 0) {
        result.push(item);
      }
    });
    return result;
  }
}