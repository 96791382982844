import { Component } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-personal-account',
  templateUrl: './personal-account.component.html',
  styleUrls: ['./personal-account.component.scss']
})
export class PersonalAccountComponent {
  constructor(
    private appService: AppService
  ) { }
  
  logout(): void {
    this.appService.logout();
  }
}
