import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { AppService } from 'src/app/app.service';
import { CaseBigPhotoComponent } from 'src/app/component/case-big-photo/case-big-photo.component';
import { Utility } from 'src/app/utility/Utility';
import { JsonCase } from 'src/model/dto';
import { SeverityEnum, ToastMsg } from 'src/model/page';

@Component({
  selector: 'tudi-case-dialog',
  templateUrl: './case-dialog.component.html',
  styleUrls: ['./case-dialog.component.scss']
})
export class CaseDialogComponent {
  @ViewChild(CaseBigPhotoComponent) caseBigPhoto: CaseBigPhotoComponent;
  @Input() case: JsonCase;
  @Output() tagging: EventEmitter<JsonCase> = new EventEmitter();
  @Output() save: EventEmitter<JsonCase> = new EventEmitter();
  @Output() delete: EventEmitter<JsonCase> = new EventEmitter();
  isShow = false;
  tongueTypes: SelectItem[] = [];

  constructor(
    private appService: AppService,
    private msgService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.tongueTypes = [
      {label: 'Shape', value: 1},
      {label: 'Color', value: 2},
      {label: 'Coating', value: 3}
    ];
  }

  show(): void {
    this.isShow = true;
  }
  getCasePhoto(): string {
    const data = {
      caseId: this.case.id,
      token: this.case.token
    };
    return this.appService.generateGetUrl('data', 'getCaseTongueImage', data);
  }

  openBigPhoto(): void {
    this.caseBigPhoto.show();
  }

  getTaggingStr(type: number): string[] {
    return this.case.tongues
      .filter(tongue => tongue.tongue.type === type)
      .map(tongue => Utility.toTongueStr(tongue));
  }

  onEdit(): void {

  }

  onTagging(): void {
    this.isShow = false;
    this.tagging.emit(this.case);
  }

  onDelete(event: Event): void {
    console.log('onDelete');
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure to delete the case of ' + this.case.name + '?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.appService.setBlock(true);
        this.appService.post('data', 'deleteCase', {caseId: this.case.id}).subscribe({
          next: (res: JsonCase) => {
            this.appService.setBlock(false);
            this.delete.emit(res);
            this.msgService.add(new ToastMsg(SeverityEnum.success, 'Succeed to delete Case', ''));
            this.isShow = false;
          },
          error: err => {
            this.appService.setBlock(false);
          }
        });
      }
    });
  }

  onClincReport(): void {
    this.router.navigate(['/show-report', this.case.id, this.case.token]);
  }
}
