import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ImageFileSelectorComponent } from 'src/app/component/image-file-selector/image-file-selector.component';
import { JsonTongue } from 'src/model/dto';
import { ContentType, SeverityEnum, ToastMsg } from 'src/model/page';

@Component({
  selector: 'tudi-tongue-dialog',
  templateUrl: './tongue-dialog.component.html',
  styleUrls: ['./tongue-dialog.component.scss']
})
export class TongueDialogComponent {
  @ViewChild(ImageFileSelectorComponent) imageUploader: ImageFileSelectorComponent;
  @Input() tongue: JsonTongue;
  @Output() save: EventEmitter<JsonTongue> = new EventEmitter();

  isShow = false;
  editedTongue: JsonTongue;
  originalImage = true;
  statusOptions: SelectItem[];

  constructor(
    private appService: AppService,
    private msgService: MessageService
  ) {}

  ngOnInit(): void {
    this.statusOptions = [
      {label: 'Enable', value: 1},
      {label: 'Disable', value: 0}
    ];
  }

  show(isShow: boolean): void {
    if (isShow) {
      setTimeout(() => {
        this.editedTongue = {
          id: this.tongue.id,
          name: this.tongue.name,
          type: this.tongue.type,
          pattern: this.tongue.pattern,
          symptomsMale: this.tongue.symptomsMale,
          symptomsFemale: this.tongue.symptomsFemale,
          recommendation: this.tongue.recommendation,
          displayOrder: this.tongue.displayOrder,
          modTime: this.tongue.modTime,
          status: this.tongue.status
        };
        this.originalImage = this.tongue.id !== 0;
        this.isShow = true;
      }, 100);
    } else {
      this.isShow = false;
    }
  }

  async onSave(): Promise<void> {
    const newTongue: JsonTongue = await firstValueFrom(this.appService.postBody('admin', 'updateTongue', JSON.stringify(this.editedTongue), ContentType.json));
    if (!this.originalImage) {
      const data = {
        tongueId: newTongue.id,
        icon: this.imageUploader.image
      };
      console.log(this.imageUploader.image);
      console.log(data);
      await firstValueFrom(this.appService.postForm('admin', 'uploadTongueIcon', data));
    }
    this.msgService.add(new ToastMsg(SeverityEnum.success, 'Succeed to update the tongue data', 'Succeed to update tongue ' + newTongue.name + ''));
    this.save.emit(newTongue);
    this.show(false);
  }

  get header(): string {
    if (this.tongue && this.tongue.id === 0) {
      return 'New Tongue';
    } else {
      return 'Edit Tongue';
    }
  }

  onImageUpload(image: File): void {
    this.originalImage = false;
  }

  get originImageUrl(): string {
    const data = {
      tongueId: this.editedTongue.id
    };
    return this.appService.generateGetUrl('data', 'getTongueIcon', data);
  }
}
