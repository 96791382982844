<tudi-menu></tudi-menu>
<div class="mt-3">
  <button pButton label="New Account" (click)="newAccount()"></button>
</div>
<div class="mt-3">
  <p-table [columns]="cols" [value]="accounts" dataKey="id" responsiveLayout="stack" [breakpoint]="'720px'"  [paginator]="true" [rows]="10">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="table-cell" *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
          <td [attr.colspan]="columns.length">
              No accounts are found.
          </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-cols="columns">
      <tr (click)="onSelect(row)">
        <ng-container *ngFor="let col of cols">
          <ng-container [ngSwitch]="col.field">
            <td class="table-cell" *ngSwitchCase="'role'">
              <span class="p-column-title">{{ col.header }}: </span>{{getRoleName(row[col.field])}}
            </td>
            <td class="table-cell" *ngSwitchCase="'status'">
              <span class="p-column-title">{{ col.header }}: </span>
              <span *ngIf="row[col.field] === 1" clas="text-blue-500">Enable</span>
              <span *ngIf="row[col.field] !== 1" class="text-red-500">Disable</span>
            </td>
            <td *ngSwitchDefault><span class="p-column-title">{{ col.header }}: </span>{{row[col.field]}}</td>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>
<tudi-account-dialog [account]="editedAccount" (save)="onSave($event)"></tudi-account-dialog>