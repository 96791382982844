import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShowReportComponent } from './show-report/show-report.component';
import { UserInputComponent } from './user-input/user-input.component';
import { UserReportComponent } from './user-report/user-report.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './auth-guard.service';
import { CaseListComponent } from './case-list/case-list.component';
import { TongueListComponent } from './tongue-list/tongue-list.component';
import { AccountListComponent } from './account-list/account-list.component';
import { PersonalAccountComponent } from './personal-account/personal-account.component';

const routes: Routes = [
  { path: '', redirectTo: 'user-input', pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  { path: 'case-list', component: CaseListComponent, canActivate: [AuthGuardService] },
  { path: 'tongue-list', component: TongueListComponent, canActivate: [AuthGuardService] },
  { path: 'account-list', component: AccountListComponent, canActivate: [AuthGuardService] },
  { path: 'personal-account', component: PersonalAccountComponent, canActivate: [AuthGuardService] },
  { path: 'user-input', component: UserInputComponent, canActivate: [AuthGuardService] },
  { path: 'user-report', component: UserReportComponent, canActivate: [AuthGuardService] },
  { path: 'show-report', component: ShowReportComponent, canActivate: [AuthGuardService] },
  { path: 'show-report/:caseId', component: ShowReportComponent },
  { path: 'show-report/:caseId/:token', component: ShowReportComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
