import { Component, OnInit } from '@angular/core';
import { JsonCaseReport } from 'src/model/dto';
import { TongueService } from '../tongue.service';

@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.scss']
})
export class UserReportComponent implements OnInit {
  report: JsonCaseReport;
  caseImg: string;

  constructor(
    private tongueService: TongueService
  ) { }

  ngOnInit(): void {
    /*
    const data = {
      createTime: "2023/02/08 10:15:22",
      email: "linhuanyu@gmail.com",
      id: 1,
      isSendMail: 0,
      name: "LIN",
      phone: "0939292098",
      doctor: 'Dr. Lin',
      gender: 1,
      token: "b4ea9e6667b03fa1fc004bff95ec5127",
      tongues: []
    }
    this.tongueService.uploadedCase = data;
    */
    this.tongueService.checkPage();
    this.caseImg = this.tongueService.getCaseImgUrl();
    this.tongueService.getCaseReport().subscribe(res => {
      this.report = res;
      this.tongueService.sendCaseMail().subscribe(res => {});
    })
  }

  returnToInput(): void {
    this.tongueService.reset();
  }

}
