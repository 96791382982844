export enum ContentType {
  'form' = 'application/x-www-form-urlencoded;charset=utf8',
  'json' = 'application/json;charset=utf8'
}

export enum SeverityEnum {
  'success' = 'success',
  'info' = 'info',
  'warn' = 'warn',
  'error' = 'error'
}

// 系統訊息
export class ToastMsg {
  severity: SeverityEnum;
  summary: string;
  detail: string;
  life?: number;

  constructor(severity: SeverityEnum, summary: string, detail: string, life = 3000) {
    this.severity = severity;
    this.summary = summary;
    this.detail = detail;
    this.life = life;
  }
}

export class TableColumn {
  header: string;
  field: string;
}