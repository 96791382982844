<p-dialog header="Select Location" *ngIf="isShow" [(visible)]="isShow">
  <div class="flex">
    <div class="w-6">
      <div class="text-4xl font-bold text-center" (click)="onClickAll()">All</div>
    </div>
    <div class="w-6">
      <div class="text-4xl font-bold text-center" (click)="onClickNone()">None</div>
    </div>
  </div>
  <div class="flex">
    <div class="side-area flex align-items-center justify-content-center" [ngClass]="getLocationClass(5)" (click)="onClickLocation(5)">
      <div class="text-4xl font-bold">Left</div>
    </div>
    <div class="flex flex-column">
      <div class="middle-area flex align-items-center justify-content-center" [ngClass]="getLocationClass(2)" (click)="onClickLocation(2)">
        <div class="text-4xl font-bold">Root</div>
      </div>
      <div class="middle-area flex align-items-center justify-content-center" [ngClass]="getLocationClass(1)" (click)="onClickLocation(1)">
        <div class="text-4xl font-bold">Center</div>
      </div>
      <div class="middle-area flex align-items-center justify-content-center" [ngClass]="getLocationClass(3)" (click)="onClickLocation(3)">
        <div class="text-4xl font-bold">Front</div>
      </div>
      <div class="tip-area flex align-items-center justify-content-center" [ngClass]="getLocationClass(4)" (click)="onClickLocation(4)">
        <div class="text-4xl font-bold">Tip</div>
      </div>
    </div>
    <div class="side-area flex align-items-center justify-content-center" [ngClass]="getLocationClass(6)" (click)="onClickLocation(6)">
      <div class="text-4xl font-bold">Right</div>
    </div>
  </div>
  <div class="mt-3 text-center">
    <button pButton class="w-3" label="OK" (click)="onClickSave()"></button>
  </div>
</p-dialog>