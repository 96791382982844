import { Component, Input } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { JsonCase } from 'src/model/dto';

@Component({
  selector: 'tudi-case-big-photo',
  templateUrl: './case-big-photo.component.html',
  styleUrls: ['./case-big-photo.component.scss']
})
export class CaseBigPhotoComponent {
  @Input() case: JsonCase;

  constructor(
    private appService: AppService,
  ) { }

  isShow = false;

  show(): void {
    this.isShow = true;
  }

  
  getCasePhoto(): string {
    const data = {
      caseId: this.case.id,
      token: this.case.token
    };
    return this.appService.generateGetUrl('data', 'getCaseTongueImage', data);
  }

  closeBigPhoto(): void {
    this.isShow = false;
  }
}
