import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JsonCase, JsonCaseReport, JsonTongue, UploadedCase } from 'src/model/dto';
import { ContentType } from 'src/model/page';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class TongueService {
  uploadedCase: JsonCase;

  constructor(
    private service: AppService,
    private router: Router
  ) { }

  checkPage(): void {
    if (!this.uploadedCase) {
      this.router.navigate(['/user-input']);
    }
  }

  getCaseImgUrl(): string {
    if (this.uploadedCase) {
      const data = {
        caseId: this.uploadedCase.id,
        token: this.uploadedCase.token
      };
      return this.service.generateGetUrl('data', 'getCaseTongueImage', data);
    } else {
      return '';
    }
  }

  getTongues(): Observable<JsonTongue[]> {
    return this.service.post('data', 'getTongues', {});
  }

  getCaseReport(): Observable<JsonCaseReport> {
    if (this.uploadedCase) {
      const data = {
        caseId: this.uploadedCase.id,
        token: this.uploadedCase.token
      };
      return this.service.post('data', 'viewCaseReport', data);
    } else {
      this.router.navigate(['/user-input']);
      return null;
    }
  }

  sendCaseMail(): Observable<JsonCase> {
    return this.service.post('data', 'sendCaseMail', {caseId: this.uploadedCase.id});
  }

  reset(): void {
    this.uploadedCase = null;
    this.router.navigate(['/user-input']);
  }
}
