import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { MemberRole } from 'src/model/dto';

@Component({
  selector: 'tudi-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  isShow = false;
  
  constructor(
    private appService: AppService,
    private router: Router
  ) {}

  get account(): string {
    return this.appService.loginInfo.account;
  }

  display(name: string): boolean {
    let functions = ['case-list', 'new-case', 'accounts', 'tongues', 'personal-account'];
    if (this.appService.loginInfo.role === MemberRole.doctor) {
      functions = ['case-list', 'new-case', 'personal-account'];
    }
    return functions.indexOf(name) !== -1;
  }

  onClick(name: string): void {
    const url = {
      'case-list': 'case-list',
      'new-case': 'user-input',
      'accounts': 'account-list',
      'tongues': 'tongue-list',
      'personal-account': 'personal-account'
    };
    if (url[name]) {
      this.router.navigate([url[name]]);
    }
  }

  toggle(): void {
    this.isShow = !this.isShow;
  }
}
