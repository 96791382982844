import { Component, EventEmitter, Input, Output } from '@angular/core';
import { JsonCaseTongue, JsonTongue } from 'src/model/dto';

@Component({
  selector: 'tudi-tongue-location-selector',
  templateUrl: './tongue-location-selector.component.html',
  styleUrls: ['./tongue-location-selector.component.scss']
})
export class TongueLocationSelectorComponent {
  @Input() tongue: JsonTongue
  @Output() onSave: EventEmitter<JsonCaseTongue> = new EventEmitter();

  isShow = false;
  locations: number[];

  show(): void {
    this.locations = [];
    this.isShow = true;
  }

  isSelected(location: number): boolean {
    return this.locations.indexOf(location) !== -1;
  }

  onClickLocation(location: number): void {
    if (this.isSelected(location)) {
      this.locations.splice(this.locations.indexOf(location), 1);
    } else {
      this.locations.push(location);
    }
  }

  getLocationClass(location: number): string {
    if (this.isSelected(location)) {
      return 'location-selected';
    } else {
      return 'location-normal';
    }
  }

  onClickSave(): void {
    const result: JsonCaseTongue = {
      tongue: this.tongue,
      locations: this.locations
    };
    this.onSave.emit(result);
    this.isShow = false;
  }

  onClickAll(): void {
    this.locations = [1, 2, 3, 4, 5, 6];
  }
  
  onClickNone(): void {
    this.locations = [];
  }
}
