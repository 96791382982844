import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { AppService } from 'src/app/app.service';
import { JsonCase, JsonCaseTongue, JsonTongue, TongueType } from 'src/model/dto';
import { ContentType, SeverityEnum, ToastMsg } from 'src/model/page';
import { TongueLocationSelectorComponent } from '../tongue-location-selector/tongue-location-selector.component';
import { CaseBigPhotoComponent } from '../case-big-photo/case-big-photo.component';
import { Utility } from 'src/app/utility/Utility';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'tudi-case-tagging',
  templateUrl: './case-tagging.component.html',
  styleUrls: ['./case-tagging.component.scss']
})
export class CaseTaggingComponent {
  @ViewChild(TongueLocationSelectorComponent) tongueLocationSelector: TongueLocationSelectorComponent;
  @Input() patientCase: JsonCase;
  @Output() onUpdate: EventEmitter<JsonCase> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onUpdateAndNext: EventEmitter<JsonCase> = new EventEmitter();

  tongueType: number;
  tongues: {[key: number]: JsonTongue[]} = {};
  currentTongues: JsonTongue[] = [];
  typeOptions: SelectItem[] = [];
  selectedTongue: JsonCaseTongue[] = [];
  editedTongue: JsonTongue;

  constructor(
    private appService: AppService,
    private msgService: MessageService
  ) { }

  ngOnInit(): void {
    this.init();
    this.reset();
  }

  async init(): Promise<void> {
    this.appService.setBlock(true);
    this.typeOptions = [
      {label: 'Shape', value: 1},
      {label: 'Color', value: 2},
      {label: 'Coating', value: 3}
    ];
    for (const type of this.typeOptions) {
      this.tongues[type.value] = await firstValueFrom(this.appService.postWithType<JsonTongue[]>('data', 'getTongues', {type: type.value, filterDisabled: 1}));
    }
    this.appService.setBlock(false);
  }

  showTongues(): void {
    console.log('change list for : ', this.tongueType);
    console.log(this.currentTongues);
    this.currentTongues = this.tongues[this.tongueType];
  }

  reset(): void {
    this.selectedTongue = [...this.patientCase.tongues];
    this.tongueType = this.typeOptions[0].value;
    this.showTongues();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  getCasePhoto(): string {
    const data = {
      caseId: this.patientCase.id,
      token: this.patientCase.token
    };
    return this.appService.generateGetUrl('data', 'getCaseTongueImage', data);
  }

  getTongueIcon(tongue: JsonTongue): string {
    const data = {
      tongueId: tongue.id
    };
    return this.appService.generateGetUrl('data', 'getTongueIcon', data);
  }

  update(next: boolean): void {
    const newCase: JsonCase = JSON.parse(JSON.stringify(this.patientCase));
    newCase.tongues = this.selectedTongue;
    console.log('newCase', newCase);
    this.appService.postBody('data', 'updateCaseTongue', JSON.stringify(newCase), ContentType.json).subscribe((res: JsonCase) => {
      this.msgService.add(new ToastMsg(SeverityEnum.success, 'Succeed to update tongue tags', ''));
      if (next) {
        this.onUpdateAndNext.emit(res);
      } else {
        this.onUpdate.emit(res);
      }
    });
  }

  cancel(): void {
    this.onCancel.emit();
  }

  hasLocation(tongueType: number): boolean {
    return Utility.hasLocation(tongueType);
  }

  onClickTongueButton(tongue: JsonTongue): void {
    if (this.isTongueSelected(tongue)) {
      this.selectedTongue.splice(this.selectedTongue.findIndex(selected => selected.tongue.id === tongue.id), 1);
    } else {
      if (this.hasLocation(tongue.type)) {
        this.editedTongue = tongue;
        this.tongueLocationSelector.show();
      } else {
        this.selectedTongue.push({
          locations: [],
          tongue: tongue
        });
      }
    }
  }

  onSaveLocation(caseTongue: JsonCaseTongue): void {
    this.selectedTongue.push(caseTongue);
    this.editedTongue = null;
  }

  isTongueSelected(tongue: JsonTongue): boolean {
    return this.selectedTongue.findIndex(selected => selected.tongue.id === tongue.id) !== -1;
  }

  getLocationClass(tongue: JsonTongue, location: number): string {
    const castTongue = this.selectedTongue.find(select => select.tongue.id === tongue.id);
    if (castTongue && castTongue.locations.indexOf(location) !== -1) {
      return 'location-selected';
    } else {
      return 'location-normal';
    }
  }
}
