<tudi-menu></tudi-menu>
<ng-container *ngIf="!isEditOrder">
  <div class="mt-3 flex md:flex-row flex-column gap-3 justify-content-between">
    <div>
      <p-selectButton [options]="typeOptions" [(ngModel)]="selectedType" (click)="loadData()"></p-selectButton>
    </div>
    <div>
      <button pButton label="Edit Order" (click)="onEditOrder()"></button>
      <button pButton label="New Tongue" class="ml-3" (click)="onNew()"></button>
    </div>
  </div>
  <div class="mt-3">
    <p-table [columns]="cols" [value]="tongues" dataKey="id" responsiveLayout="stack" [breakpoint]="'720px'" [paginator]="true" [rows]="10">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="table-cell" *ngFor="let col of columns">{{ col.header }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="columns.length">
                No accounts are found.
            </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-cols="columns">
        <tr (click)="onSelect(row)">
          <ng-container *ngFor="let col of cols">
            <ng-container [ngSwitch]="col.field">
              <td class="table-cell" *ngSwitchCase="'id'">
                <img [src]="getIconUrl(row[col.field])">
              </td>
              <td class="table-cell" *ngSwitchCase="'status'">
                <span class="p-column-title">{{ col.header }}: </span>
                <span *ngIf="row[col.field] === 1" class="text-blue-500">Enable</span>
                <span *ngIf="row[col.field] !== 1" class="text-red-500">Disable</span>
              </td>
              <td *ngSwitchDefault><span class="p-column-title">{{ col.header }}: </span>{{row[col.field]}}</td>
            </ng-container>
          </ng-container>
        </tr>
      </ng-template>
    </p-table>
  </div>
</ng-container>
<div class="mt-3" *ngIf="isEditOrder">
  <p-orderList [value]="tongues"  [listStyle]="{ 'max-height': '65vh' }" header="Tongue List">
    <ng-template let-tongue pTemplate="item">
      <div class="flex align-items-center p-2 w-full flex-wrap">
        <div class="w-6 text-center">
          <img [src]="getIconUrl(tongue.id)">
        </div>
        <div class="w-6 text-center">
          {{tongue.name}}
        </div>
      </div>
    </ng-template>
  </p-orderList>
  <div class="mt-3 w-full text-center">
    <button class="w-6" pButton label="Save" (click)="onSaveOrder()"></button>
  </div>
</div>
<tudi-tongue-dialog [tongue]="editedTongue" (save)="onSave($event)"></tudi-tongue-dialog>