import { Component, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { MessageService, SelectItem } from 'primeng/api';
import { ContentType, SeverityEnum, TableColumn, ToastMsg } from 'src/model/page';
import { JsonTongue, JsonTongueOrderList } from 'src/model/dto';
import { TongueDialogComponent } from './tongue-dialog/tongue-dialog.component';

@Component({
  selector: 'app-tongue-list',
  templateUrl: './tongue-list.component.html',
  styleUrls: ['./tongue-list.component.scss']
})
export class TongueListComponent {
  @ViewChild(TongueDialogComponent) tongueDialog: TongueDialogComponent;
  typeOptions: SelectItem[];
  cols: TableColumn[];
  selectedType: number = 1;
  tongues: JsonTongue[] = [];
  editedTongue: JsonTongue;
  isEditOrder = false;

  constructor(
    private appService: AppService,
    private msgService: MessageService
  ) {}

  ngOnInit(): void {
    this.typeOptions = [
      {label: 'Shape', value: 1},
      {label: 'Color', value: 2},
      {label: 'Coating', value: 3}
    ];
    this.cols = [
      {header: 'Icon', field: 'id'},
      {header: 'Name', field: 'name'},
      {header: 'TCM Pattern', field: 'pattern'},
      {header: 'Status', field: 'status'}
    ];
    this.loadData();
  }

  loadData(): void {
    this.appService.post('data', 'getTongues', {type: this.selectedType}).subscribe((res: JsonTongue[]) => {
      this.tongues = res;
    });
  }

  onSelect(tongue: JsonTongue): void {
    this.editedTongue = tongue;
    this.tongueDialog.show(true);
  }

  getIconUrl(tongueId: number): string {
    const data = {
      tongueId: tongueId
    };
    return this.appService.generateGetUrl('data', 'getTongueIcon', data);
  }

  onNew(): void {
    const maxOrder = this.tongues.map(t => t.displayOrder).reduce((res, cur) => res >= cur? res: cur, 0);
    this.editedTongue = {
      id: 0,
      name: '',
      type: this.selectedType,
      pattern: '',
      symptomsMale: '',
      symptomsFemale: '',
      recommendation: '',
      displayOrder: maxOrder + 1,
      modTime: null,
      status: 1
    };
    console.log(this.editedTongue);
    this.tongueDialog.show(true);
  }

  onSave(tongue: JsonTongue): void {
    this.loadData();
  }

  onEditOrder(): void {
    this.isEditOrder = true;
  }

  onSaveOrder(): void {
    const data: JsonTongueOrderList = {
      type: this.selectedType,
      ids: this.tongues.map(tongue => tongue.id)
    };
    this.appService.postBody('admin', 'updateTongueOrder', JSON.stringify(data), ContentType.json).subscribe((res: JsonTongue[]) => {
      this.tongues = res;
      this.isEditOrder = false;
      this.msgService.add(new ToastMsg(SeverityEnum.success, 'Succeed to modify the order', ''));
    });
  }
}
