import { JsonCaseTongue, TongueLocation, TongueType } from "src/model/dto";

export class Utility {
  static hasLocation(tongueType: number): boolean {
    return [TongueType.Color].indexOf(tongueType) !== -1;
  }

  static toTongueStr(tongue: JsonCaseTongue): string {
    if (!Utility.hasLocation(tongue.tongue.type)) {
      return tongue.tongue.name;
    } else {
      const table = {};
      table[TongueLocation.Center] = 'Center';
      table[TongueLocation.Front] = 'Front';
      table[TongueLocation.Root] = 'Root';
      table[TongueLocation.Tip] = 'Tip';
      table[TongueLocation.Left] = 'Left';
      table[TongueLocation.Right] = 'Right';
      const locationStr = tongue.locations.map(location => table[location]).join(', ');
      if (locationStr) {
        return tongue.tongue.name + ' (' + locationStr + ')';
      } else {
        return tongue.tongue.name;
      }
    }
  }
}