import { Component, EventEmitter, Output } from '@angular/core';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'tudi-camera-dialog',
  templateUrl: './camera-dialog.component.html',
  styleUrls: ['./camera-dialog.component.scss']
})
export class CameraDialogComponent {
  @Output() onCapture: EventEmitter<WebcamImage> = new EventEmitter();
  isShow = false;
  trigger: Subject<void> = new Subject<void>();
  switchCamera: Subject<string> = new Subject<string>();

  constructor(
  ){}

  show(): void {
    let previousCameraDeviceId = this.cameraDeviceId? this.cameraDeviceId: null;
    this.isShow = true;
    if (previousCameraDeviceId) {
      setTimeout(() => {
        this.switchCamera.next(previousCameraDeviceId);
      }, 100);
    }
  }

  hide(): void {
    this.isShow = false;
  }

  takeImage(): void {
    this.trigger.next();
  }

  captureImage(image: WebcamImage): void {
    this.onCapture.emit(image);
    this.hide();
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  get switchCameraObservable(): Observable<string> {
    return this.switchCamera.asObservable();
  }

  get videoOptions(): MediaTrackConstraints {
    return {
      width: this.cameraWidth,
      height: this.cameraHeight,
      facingMode: 'environment'
    };
  }

  get cameraWidth(): number {
    if (this.isPortrait) {
      return window.innerWidth * 0.9;
    } else {
      return window.innerWidth * 0.7;
    }
  }

  get cameraHeight(): number {
    if (this.isPortrait) {
      return window.innerHeight * 0.7;      
    } else {
      return window.innerHeight * 0.9;
    }
  }

  get cameraAspectRatio(): number {
    return this.cameraWidth / this.cameraHeight;
  }

  get cameraDeviceId(): string {
    return localStorage.getItem('cameraDeviceId');
  }

  set cameraDeviceId(value: string) {
    localStorage.setItem('cameraDeviceId', value);
  }

  cameraSwitched(deviceId: string): void {
    if (deviceId) {
      this.cameraDeviceId = deviceId;
    }
  }

  get isPortrait(): boolean {
    return window.innerHeight > window.innerWidth;
  }

  showProperties(): void {
    let lines = [];
    lines.push('cameraDeviceId: ' + this.cameraDeviceId);
    lines.push('cameraWidth: ' + this.cameraWidth);
    lines.push('cameraHeight: ' + this.cameraHeight);
    lines.push('cameraAspect: ' + this.cameraAspectRatio);
    alert(lines.join('\r\n'));
;  }
}