<div class="flex justify-content-center">
  <div class="md:w-30rem w-full">
    <div class="mt-6 text-center w-full">
      <img src="./assets/images/logo.png">
    </div>
    <div class="mt-3 w-full flex">
      <div class="w-3">Account:</div>
      <div class="w-9">
        <input pInputText class="w-full" type="text" [(ngModel)]="account">
      </div>
    </div>
    <div class="mt-3 w-full flex">
      <div class="w-3">Password:</div>
      <div class="w-9">
        <input pInputText class="w-full" type="password" [(ngModel)]="password">
      </div>
    </div>
    <div class="mt-3 w-full">
      <button pButton label="Login" class="w-full" (click)="onLogin()"></button>
    </div>
  </div>
</div>
