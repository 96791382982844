import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { AppService } from 'src/app/app.service';
import { JsonMember } from 'src/model/dto';
import { ContentType, SeverityEnum, ToastMsg } from 'src/model/page';

@Component({
  selector: 'tudi-account-dialog',
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.scss']
})
export class AccountDialogComponent {
  @Input() account: JsonMember;
  @Output() save: EventEmitter<JsonMember> = new EventEmitter();
  currentAccount: JsonMember;
  isShow = false;
  roleOptions: SelectItem[];
  statusOptions: SelectItem[];

  constructor(
    private appService: AppService,
    private msgService: MessageService
  ) {}

  ngOnInit(): void {
    this.roleOptions = [
      {label: 'Administrator', value: 1},
      {label: 'Doctor', value: 2},
    ];
    this.statusOptions = [
      {label: 'Enable', value: 1},
      {label: 'Disable', value: 0}
    ];
  }

  show(): void {
    if (this.account) {
      this.currentAccount = JSON.parse(JSON.stringify(this.account));
      this.currentAccount.password = '';
    } else {
      this.currentAccount = {
        id: 0,
        account: '',
        password: '',
        name: '',
        role: 2,
        status: 1
      };
    }
    this.isShow = true;
  }

  get header(): string {
    if (!this.currentAccount) {
      return '';
    } else if (this.currentAccount.id === 0) {
      return "New Account";
    } else {
      return "Edit Account "+this.currentAccount.account;
    }
  }

  get passwordPlaceHolder(): string {
    if (this.currentAccount && this.currentAccount.id !== 0) {
      return 'Fill the textbox to modify password'
    } else {
      return '';
    }
  }

  onSave(): void {
    if (this.currentAccount.name === '') {
      this.msgService.add(new ToastMsg(SeverityEnum.error, 'Information Error', 'Name cannot be empty'));
    } else if (this.currentAccount.id === 0 && this.currentAccount.password === '') {
      this.msgService.add(new ToastMsg(SeverityEnum.error, 'Information Error', 'Password cannot be empty'));
    }
    this.appService.postBody('admin', 'updateMember', JSON.stringify(this.currentAccount), ContentType.json).subscribe((res: JsonMember) => {
      if (this.currentAccount.id === 0) {
        this.msgService.add(new ToastMsg(SeverityEnum.success, 'Succeed to create new account', 'Create new account '+this.currentAccount.account));
      } else {
        this.msgService.add(new ToastMsg(SeverityEnum.success, 'Succeed to save modification', 'Save the modification of the account '+this.currentAccount.account));
      }
      this.save.emit(res);
      this.isShow = false;
    });
  }
}
