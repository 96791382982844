<div class="dialog-area flex" *ngIf="isShow && !isPortrait">
  <div class="camera-area-landscape flex flex-column align-items-center justify-content-center">
    <webcam [allowCameraSwitch]="true" [trigger]="triggerObservable" [width]="cameraWidth" [height]="cameraHeight" (imageCapture)="captureImage($event)" [videoOptions]="videoOptions" [switchCamera]="switchCameraObservable" (cameraSwitched)="cameraSwitched($event)"></webcam>
  </div>
  <div class="flex flex-column align-items-center justify-content-center button-area-landscape">
    <div class="take-icon" (click)="takeImage()"></div>
  </div>
  <div class="close-icon">
    <i class="pi pi-times delete-icon" (click)="hide()"></i>
  </div>
  <div class="fixed top-0 left-0 text-white" (click)="showProperties()">Camera Properties</div>
</div>
<div class="dialog-area flex flex-column" *ngIf="isShow && isPortrait">
  <div class="camera-area-portrait flex flex-column align-items-center justify-content-center">
    <webcam [allowCameraSwitch]="true" [trigger]="triggerObservable" [width]="cameraWidth" [height]="cameraHeight" [aspectRatio]="cameraAspectRatio" (imageCapture)="captureImage($event)" [videoOptions]="videoOptions" [switchCamera]="switchCameraObservable" (cameraSwitched)="cameraSwitched($event)"></webcam>
  </div>
  <div class="flex flex-column align-items-center justify-content-center button-area-portrait">
    <div class="take-icon" (click)="takeImage()"></div>
  </div>
  <div class="close-icon">
    <i class="pi pi-times delete-icon" (click)="hide()"></i>
  </div>
  <div class="fixed top-0 left-0 text-white" (click)="showProperties()">Camera Properties</div>
</div>