<p-dialog header="Edit Case" *ngIf="isShow" [(visible)]="isShow">
  <div class="dialog-area" *ngIf="case">
    <div class="md:flex block">
      <div class="photo-area">
        <img [src]="getCasePhoto()" (click)="openBigPhoto()"/>
      </div>
      <div class="info-area">
        <div>
          <strong>ID:</strong> {{case.id}}
        </div>
        <div class="mt-3">
          <strong>Name:</strong> {{case.name}}
        </div>
        <div class="mt-3">
          <strong>Gender:</strong> {{case.gender | gender}}
        </div>
        <div class="mt-3">
          <strong>E-Mail:</strong> {{case.email}}
        </div>
        <div class="mt-3">
          <strong>Symptom:</strong> {{case.symptom}}
        </div>
        <div class="mt-3">
          <strong>Doctor:</strong> {{case.doctor.name}}
        </div>
        <div class="mt-3">
          <strong>Create Time:</strong> {{case.createTime | date:'yyyy/MM/dd HH:mm:SS'}}
        </div>
      </div>
      <div class="tag-area">
        <ng-container *ngFor="let tongueType of tongueTypes">
          <div class="tag-header">{{tongueType.label}}</div>
          <div class="tag-list">
            <div *ngFor="let str of getTaggingStr(tongueType.value)" class="tag">
              {{str}}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="flex flex-wrap mt-3 gap-3 justify-content-end">
      <div class="md:w-8rem w-full">
        <button class="w-full" pButton label="Edit" (click)="onEdit()" [disabled]="true"></button>
      </div>
      <div class="md:w-8rem w-full">
        <button class="w-full" pButton label="Tagging" (click)="onTagging()"></button>
      </div>
      <div class="md:w-8rem w-full">
        <button class="w-full" pButton label="Report" (click)="onClincReport()"></button>
      </div>
      <div class="md:w-8rem w-full">
        <button class="w-full p-button-danger" pButton label="Delete" (click)="onDelete($event)"></button>
      </div>
    </div>
  </div>
</p-dialog>
<tudi-case-big-photo [case]="case"></tudi-case-big-photo>