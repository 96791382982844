import { Component, Input, OnInit } from '@angular/core';
import { JsonCaseReport } from 'src/model/dto';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  @Input() report: JsonCaseReport;
  @Input() caseImg: string;

  constructor() { }

  ngOnInit(): void {
  }

}
