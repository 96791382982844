<div class="case-info">Case {{patientCase.id}} {{patientCase.name}}</div>
<div class="md:flex">
  <div class="tongue-photo-area">
    <img [src]="getCasePhoto()"/>
  </div>
  <div class="left-area">
    <div class="tongue-tag-area">
      <ng-container *ngFor="let tongueType of typeOptions">
        <div class="font-bold mt-3">{{tongueType.label}}</div>
        <div class="flex flex-wrap">
          <div *ngFor="let tongue of tongues[tongueType.value]" class="tongue-icon-cell" (click)="onClickTongueButton(tongue)">
            <div class="cell-icon-block">
              <div *ngIf="!isTongueSelected(tongue)" class="tongue-icon-cell-img">
                <img [src]="getTongueIcon(tongue)">
              </div>
              <div *ngIf="isTongueSelected(tongue) && !hasLocation(tongue.type)" class="tongue-icon-cell-check">
                <i class="pi pi-check"></i>
              </div>
              <div *ngIf="isTongueSelected(tongue) && hasLocation(tongue.type)" class="tongue-icon-cell-check flex justify-content-between">
                <div class="location-mark flex">
                  <div class="w-4 h-full" [ngClass]="getLocationClass(tongue, 5)"></div>
                  <div class="w-4 h-full flex flex-column">
                    <div class="w-full location-middle-height" [ngClass]="getLocationClass(tongue, 2)"></div>
                    <div class="w-full location-middle-height" [ngClass]="getLocationClass(tongue, 1)"></div>
                    <div class="w-full location-middle-height" [ngClass]="getLocationClass(tongue, 3)"></div>
                    <div class="w-full location-tip-height" [ngClass]="getLocationClass(tongue, 4)"></div>
                  </div>
                  <div class="w-4 h-full" [ngClass]="getLocationClass(tongue, 6)"></div>
                </div>
              </div>
            </div>
            <div class="cell-label-block">
              {{tongue.name}}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="button-area">
      <button class="w-3 p-button-warning" pButton label="Cancel" (click)="cancel()"></button>
      <button class="w-3 ml-1" pButton label="Save" (click)="update(false)"></button>
      <button class="w-5 ml-1" pButton label="Save & Next" (click)="update(true)"></button>
    </div>
  </div>
</div>

<tudi-tongue-location-selector [tongue]="editedTongue" (onSave)="onSaveLocation($event)"></tudi-tongue-location-selector>