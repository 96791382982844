import { Component } from '@angular/core';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'tongue diagnosis';
  constructor(
    private appService: AppService
  ) {}

  get block(): boolean {
    return this.appService.isBlock;
  }
}
