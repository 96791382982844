<div class="page" *ngIf="report">
  <div class="content">
    <div class="first-area">
      <div class="first-first-area">
        <div class="name-area">
          <div>{{report.name}}</div>
          <div>Tongue Diagnosis Report</div>
        </div>
      </div>
      <div class="first-second-area">
        <div class="tongue-img-area">
          <img [src]="caseImg">
        </div>
      </div>
      <div class="first-third-area">
        <div class="tongue-logo-area">
          <img src="./assets/images/logo.png">
        </div>
      </div>
    </div>
    <div class="second-area">
      <div class="text-area">
        <p>Dear {{report.name}},</p>
        <p>Thank you for using our tongue diagnosis platform! Listed below is the data that has been calibrated from your results.</p>
        <p>Potential Symptoms or Health Concerns:</p>
        <ul>
          <li *ngFor="let symptom of report.symptoms">{{symptom}}</li>
        </ul>
        <p>Here are our health & lifestyle recomendations for your results:</p>
        <ul>
          <li *ngFor="let rcm of report.recommendation">{{rcm}}</li>
        </ul>
        <p>If you have any health concerns or health goals you want to reach, we are here to help! <strong>Show this pdf for a 20% discount on your first treatment.</strong> Call us now to make an appointment at <strong>(408)830-9002</strong>.</p>
        <div class="p-d-flex p-jc-end">
          <div>
            <p>Stay Healthy,<br>{{report.doctor}} & The Care Comfort Cure Team</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>