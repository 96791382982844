import { Component, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { JsonMember, MemberRole } from 'src/model/dto';
import { TableColumn } from 'src/model/page';
import { AccountDialogComponent } from './account-dialog/account-dialog.component';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent {
  @ViewChild(AccountDialogComponent) accountDialog: AccountDialogComponent;
  accounts: JsonMember[];
  cols: TableColumn[];
  editedAccount: JsonMember;
  constructor(
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.initCols();
    this.reset();
  }

  initCols(): void {
    this.cols = [
      {header: 'Account', field: 'account'},
      {header: 'Name', field: 'name'},
      {header: 'Role', field: 'role'},
      {header: 'Status', field: 'status'}
    ];
  }
  
  reset(): void {
    this.appService.post('admin', 'getMembers', {}).subscribe((res: JsonMember[]) => {
      this.accounts = res;
    });
  }

  onSelect(member: JsonMember): void {
    this.editedAccount = member;
    setTimeout(() => {
      this.accountDialog.show();      
    }, 100);
  }

  getRoleName(role: number): string {
    if (role === MemberRole.admin) {
      return 'Administrator';
    } else if (role === MemberRole.doctor) {
      return 'Doctor';
    } else {
      return 'Unknown';
    }
  }

  newAccount(): void {
    this.editedAccount = null;
    this.accountDialog.show();
  }

  onSave(member: JsonMember): void {
    if (this.editedAccount) {
      this.editedAccount.name = member.name;
      this.editedAccount.role = member.role;
      this.editedAccount.status = member.status;
    } else {
      this.accounts.push(member);
    }
  }
}
