import { StringUtility } from "./StringUtility";

export class DateUtility {
  static addSeconds (date: Date, seconds: number): Date {
    return new Date(date.getTime() + seconds * 1000);
  }
  static addMinutes (date: Date, minutes: number): Date {
    return this.addSeconds(date, minutes * 60);
  }
  static addHours (date: Date, hours: number): Date {
    return this.addMinutes(date, hours * 60);
  }
  static addDays (date: Date, days: number): Date {
    return this.addHours(date, days * 24);
  };
  static addWeeks (date:Date, weeks: number): Date {
    return this.addDays(date, weeks * 7);
  };
  static addMonths (date:Date, months: number) {
    let y: number = date.getFullYear();
    let m: number = date.getMonth() + 1 + months;
    const d: number = date.getDate();
    const hh: number = date.getHours();
    const mm: number = date.getMinutes();
    const ss: number = date.getSeconds();
  
    if (m > 12) {
      const addYear = Math.floor(m / 12);
      const month = m % 12;
      y = y + addYear;
      m = month;
    }
    return new Date(y, m - 1, d, hh, mm, ss);
  };
  static addYears (date: Date, years: number): Date {
    return this.addMonths(date, years * 12);
  };
  static toFormatString (date: Date, format: string): string {
    try {
      let sDataTime: string = format;

      const y: number = date.getFullYear();
      const iYY: number = y - 1911;
      const m: number = date.getMonth() + 1;
      const d: number = date.getDate();
      const hh: number = date.getHours();
      const mm: number = date.getMinutes();
      const ss: number = date.getSeconds();

      sDataTime = sDataTime.replace('YYYY', y.toString());
      sDataTime = sDataTime.replace('YYY', StringUtility.padLeft(iYY.toString(), 3));
      sDataTime = sDataTime.replace('MM', StringUtility.padLeft(m.toString(), 2));
      sDataTime = sDataTime.replace('DD', StringUtility.padLeft(d.toString(), 2));
      sDataTime = sDataTime.replace('hh', StringUtility.padLeft(hh.toString(), 2));
      sDataTime = sDataTime.replace('mm', StringUtility.padLeft(mm.toString(), 2));
      sDataTime = sDataTime.replace('ss', StringUtility.padLeft(ss.toString(), 2));
      return sDataTime;
    } catch (err) {
      return '';
    }
  };
  static toDateString (date: Date): string {
    return this.toFormatString(date, 'YYYY/MM/DD');
  }
  static toTimeString (data: Date): string {
    return this.toFormatString(data, 'YYYY/MM/DD hh:mm:ss');
  }
  static diffDay(date1: Date, date2: Date): number {
    const days = Math.abs(date1.getTime() - date2.getTime()) / 1000 / 60 / 60 / 24;
    return days;
  }

  static getCurrentAge(birthday: Date): {age: number, unit: string} {
    const daysDiff = this.diffDay(new Date(), birthday);
    if (daysDiff < 365) {
      const months = Math.floor(daysDiff / 30);
      return {age: months, unit: '月'};
    } else {
      const years = Math.floor(daysDiff / 365.25);
      return {age: years, unit: '年'};
    }
  };
}