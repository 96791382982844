<div class="relative w-full h-full container">
  <ng-container *ngIf="!displayDataUrl">
    <div class="flex flex-column gap-3">
      <div class="flex justify-content-center">
        <div class="function-icon" (click)="showCamera()">
          <i class="pi pi-camera"></i>
        </div>
      </div>
      <div class="flex justify-content-center">
        <div class="function-icon" (click)="onClickFileUpload()">
          <i class="pi pi-upload"></i>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="displayDataUrl">
    <img class="max-w-full max-h-full" [src]="displayDataUrl">
    <i class="pi pi-times delete-icon" (click)="deletePhoto()"></i>
  </ng-container>
</div>
<tudi-image-file-selector (onUpload)="uploadPhoto($event)"></tudi-image-file-selector>
<tudi-camera-dialog (onCapture)="onCaptureImage($event)"></tudi-camera-dialog>