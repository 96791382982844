import { Component, ViewChild } from '@angular/core';
import { CameraDialogComponent } from '../camera-dialog/camera-dialog.component';
import { WebcamImage } from 'ngx-webcam';
import { ImageFileSelectorComponent } from '../image-file-selector/image-file-selector.component';

@Component({
  selector: 'tudi-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent {
  @ViewChild(CameraDialogComponent) cameraDialog: CameraDialogComponent;
  @ViewChild(ImageFileSelectorComponent) imageFileSelector: ImageFileSelectorComponent;

  image: File;
  displayDataUrl: string;

  setPhoto(file: File): void {
    this.image = file;
    const reader = new FileReader();
    reader.onload = (event:any) => {
      this.displayDataUrl = event.target.result;
    }
    reader.readAsDataURL(file);
  }

  uploadPhoto(file): void {
    this.setPhoto(file);
    
  }

  convertImageToMultiPart(webcamImage: WebcamImage): File {
    const dataURI = webcamImage.imageAsDataUrl;
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? window.atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ia], 'image.jpg', { type: mimeString });
  }

  showCamera(): void {
    this.imageFileSelector.showPhotoCapture();
    //this.cameraDialog.show();
  }

  onCaptureImage(image): void {
    this.setPhoto(this.convertImageToMultiPart(image));
  }

  deletePhoto(): void {
    this.displayDataUrl = null;
    this.image = null;
  }

  onClickFileUpload(): void {
    this.imageFileSelector.show();
  }

  reset(): void {
    this.image = null;
    this.displayDataUrl = null;
  }
}
