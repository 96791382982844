import { Component, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'tudi-image-file-selector',
  templateUrl: './image-file-selector.component.html',
  styleUrls: ['./image-file-selector.component.scss']
})
export class ImageFileSelectorComponent {
  @Output() onUpload: EventEmitter<File> = new EventEmitter();

  image: File;
  displayDataUrl: string;
  captureType: string = 'environment';

  setPhoto(file: File): void {
    this.image = file;
    const reader = new FileReader();
    reader.onload = (event:any) => {
      this.displayDataUrl = event.target.result;
    }
    reader.readAsDataURL(file);
  }

  uploadPhoto(event): void {
    this.setPhoto(event.target.files[0]);
    this.onUpload.emit(this.image);
  }

  show(): void {
    document.getElementById('FileUploader').click();
  }

  showPhotoCapture(): void {
    document.getElementById('PhotoCapture').click();
  }
}
